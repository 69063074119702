@media only screen  and (max-width: 480px) {
  .hastabs {
    width: 15px;
    height: 15px;
  }
  .instruments  {
    li {
      width: 15px;
      height: 15px;

    }
  }
}


.app_overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: url("img/app.png") no-repeat top center #00ce70;
  background-size: cover;
  text-align: center;
  padding: 10% 10px 0 10px;
  color: #fff;
  img {
    max-width: 300px;

  }
.close_btn {
  display: block;
  text-align: center;
  color: #fff;

}
}

.add_app {
  display: block;
  position: fixed;
  bottom: 1px;
  left: 0;
  width: 100%;
  height: 42px;
  background-image: linear-gradient(to bottom, #ffffff, #f7f7f7);
}