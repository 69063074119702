// Fonts
@import url('https://fonts.googleapis.com/css?family=Ubuntu:400,700&subset=cyrillic,cyrillic-ext');
@import url('https://fonts.googleapis.com/css?family=Ubuntu+Mono');
// Variables
@import "_variables";
// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/jquery-bar-rating/dist/themes/fontawesome-stars";
@import "plugins/typeahead";
@import "~bootstrap-datepicker/dist/css/bootstrap-datepicker.css";
@import "~ladda/dist/ladda.min.css";

html,
body {
  height: 100%;
}

.wrapper {
  position: relative;
  min-height: 100%;
}
.app_content {
  padding-bottom: 90px;
}

footer {
  opacity: 0.8;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 80px;
}

.navbar-logo {
  display: inline-block;
  width: 30px;
  height: 20px;
  background: url("img/logo_w.svg") no-repeat;
  background-size: contain;
}
.navbar-brand {
  span {
    vertical-align: top;
    display: inline-block;
    margin-top: -1px;
    font-weight: bold;
  }
}
.navbar-form {
  .form-control, .btn-default {
    border-color: #24A661;
    color: #24A661;
  }
}

$instrument_icon_height: 20px;
a:hover {
     .instruments {
       li {
         text-decoration: none !important;
       }

     }
}
.hastabs {
  display: inline-block;
  width: $instrument_icon_height;
  height: $instrument_icon_height;
  background: url("img/mediator.svg");
  background-size: cover;
  margin-right: 10px;
}

.instruments {
  display: inline-block;
  padding: 0;
  margin: 0;
  list-style: none;
  li {
    display: inline-block;
    width: $instrument_icon_height;
    height: $instrument_icon_height;
    opacity: 0.2;
    background-size: cover;
    &.active {
      opacity: 1;
    }
    &.voice {
      background-image: url("img/microphone-2.svg");
    }
    &.drums {
      background-image: url("img/drums.svg");
    }
    &.bass {
      background-image: url("img/electric-guitar_bass.svg");
    }
    &.lead_guitar {
      background-image: url("img/electric-guitar_lead.svg");
    }
    &.rhythm_guitar {
      background-image: url("img/electric-guitar_rhytm.svg");
    }
    &.keys {
      background: url("img/piano-keys.svg");
    }
  }
}



$player-control-width: 20%;
$player-controls-height: 40px;
$player-timeline-width: 100% - $player-control-width;
$buttons-numbrer: 3;
$player-part-height: 20px;

.player {
  &.editable {
    .part {
      &.deletable {
        color: $brand-danger;
        cursor: pointer;
      }
      cursor: w-resize;
    }
    .del_cue {
      &.selected {
        border: 1px solid $brand-danger;
      }
    }
  }
  .controls {
    padding: 10px 0;
    .buttons {
      display: inline-block;
      button {
        width: 42px;
        background: none;
        border: none;
        font-size: 30px;
      }
    }

    .time {
      display: inline-block;
      float: right;
    }
  }
  .timeline {
    &:hover {
      .part {
        opacity: 0.3;
      }
    }
    .parts_container {
      margin-top: 30px;
      height: $player-part-height;
      font-size: 10px;
      position: relative;
      .part {
        top: 0;
        &:hover {
          opacity: 1;
        }
        span {
          display: block;
          transform: rotate(-45deg);
          transform-origin: bottom left;
        }
        transition: opacity .15s ease-in-out;
        padding-left: 1px;
        z-index: 1000;
        height: $player-part-height;
        display: block;
        position: absolute;
        .cue {
          top: 0;
          left: 0;
          width: 1px;
          height: $player-part-height*2;
          position: absolute;
          background: #fff;
          display: block;
        }
      }
    }
    &:hover {
      .cursor {
        display: block;
      }
    }
    .bar {
      width: 100%;
      height: 15px;
      background: $main-color;
      position: relative;
      cursor: pointer;

      .cursor {
        z-index: 1001;
        display: none;
        width: 1px;
        background: $text-color;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
      .progress {
        transition-property: width;
        height: 100%;
        background: darken($main-color, 6)
      }
    }
  }

  &.sm {
    .parts_container {
      display: none;
    }
    .bar {
      height: 7px;
    }
    .controls {
      padding: 10px 0;
      .buttons {

        button {
          width: 20px;
          font-size: 14px;
        }
      }


    }
  }
}

.monospace {
  font-family: 'Ubuntu Mono', monospace;
}
.tab-tab {
  padding-top: 10px;
}

.tabtab-panel {
  display: none;
  &.active {
    display: block;
  }
  .info {
    margin-bottom: 10px;
  }
  .content {
    font-family: 'Ubuntu Mono', monospace;
  }
}
.br-theme-fontawesome-stars-o, .br-theme-fontawesome-stars {
  .br-widget {
    a.br-selected::after, a.br-fractional::after, a.br-active:after {
      color: $brand-success;
    }
  }
}
.banner {
  position: relative;
  .cover {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .5);
    top: 0;
    left: 0;
    z-index: 1;
  }
  .container {
    z-index: 2;
    position: relative;
  }
  padding: 40px 40px;
  margin: -22px 0 22px;
  color: #fff;
  text-align: center;
  background: url("img/gw.jpg") no-repeat center center #000;
  background-size: cover;
  .header {
    b {
      font-weight: bold;
    }
    span {
      text-decoration: underline;
    }
    text-shadow: 2px 2px 2px #000;

    line-height: 1.1em;
    font-size: 46px;
    margin-bottom: 22px;
  }
  .form-control {
    width: 300px;
  }
  p {
    margin-top: 5px;
  }
  a {
    color: #fff;
    text-decoration: underline;
  }
  form {
    width: 500px;
    margin: 0 auto;
    button {
      height: 36px;
    }
  }

}
@media (max-width: 768px) {
  .banner {
    .header {
      font-size: 36px;
    }
    form {
      width: 100%;
    }
  }
}
.track-rating {
  margin-top: 3px;
}
@for $i from 1 through 5 {
  .static-rating-#{$i} {
    a:nth-child(-n+#{$i}) {
      &::after {
        color: #2ab27b !important;
      }
    }
  }
}


.instrument_col {
  text-align: right;
  white-space: nowrap;
}


.add_block {
  margin-bottom: 30px;
}

@keyframes blink {50% { color: transparent }}
.miner {
  display: none;
  position: fixed;
  bottom: 0;
  right: 0;
  .info {
    display: none;
    text-align: right;
    padding-right: 10px;
  }
  &:hover {
    .info {
      display: block;
    }
  }
  .widget {
    padding: 7px 15px;
    border-color: #24A661;
    background: #24A661;
    color: #fff;
    .dot {
      animation: 1s blink infinite;
      padding-right: 3px;
    }
    span {
      padding-right: 3px;
      font-weight: bold;
      display: inline-block;

    }
    i {
      vertical-align: middle;
      display: inline-block;
      font-size: 20px;
      height: 23px;
      cursor: pointer;
    }
  }

}

ul.simple-list {
  padding: 0;
  margin: 0;
  li {
    list-style: none;
  }
}

.song-list {
  .thumb-row {
    width: 50px;
    padding-left: 0;
    padding-top: 14px;
    padding-bottom: 14px;
    .song-thumb {
      width: 50px;
    }

  }
  &.fixed-w {
    position: relative;
    .row {
      margin-bottom: 10px;
      margin-top: 10px;
      min-height: 50px;
    }
  }
  .col-thumb{
    position: absolute;
    width:50px;
    height:100%;
    margin-left: 30px;
    z-index:1;
    .song-thumb {
      width: 50px;
    }
  }
  .col-desc{
    padding-left:100px;
    z-index:0;
  }
}

@import "mobile";